import { either, isNil, isEmpty } from 'ramda'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import sv from 'date-fns/locale/sv'

type MapToOutput<I, U = I> = (v: I) => U

export const checkNullUndefined: MapToOutput<unknown, boolean> = either(
  isEmpty,
  isNil
)

export const isValidOrgNumberFormat = (orgNr: string) => {
  const regex = new RegExp('^[0-9]{6}[-]?[0-9]{4}$')
  return regex.test(orgNr)
}

export const isValidOrgNumberChecksum = (orgNr: string) => {
  var digits = orgNr.substr(0, orgNr.length - 1).replace(/[^0-9]/g, '')
  let sum = 0

  digits.split('').forEach((digit, index) => {
    var val = parseInt(digit) * (index % 2 === 0 ? 2 : 1)
    val
      .toString()
      .split('')
      .forEach((digit2) => {
        sum += parseInt(digit2)
      })
  })

  const checksum = (Math.ceil(sum / 10) * 10 - sum) % 10

  const lastDigit = orgNr[orgNr.length - 1]

  return checksum.toString() === lastDigit
}

interface Document {
  documentMode?: any
}

const stockholmTimeZone =
  !!(window as any).MSInputMethodContext &&
  !!(document as Document).documentMode
    ? // does not take dst into consideration
      '+01:00'
    : // use arbitrary timezone with modern browsers
      'Europe/Stockholm'

export const trrDate = (date?: Date | string): Date =>
  utcToZonedTime(date ? date : new Date(), stockholmTimeZone)

export const trrDateUTC = (date: Date | string): Date =>
  zonedTimeToUtc(date, stockholmTimeZone)

export const baseTrrFormat =
  (formatStr: string) =>
  (date: Date | string = new Date()): string =>
    format(trrDate(date), formatStr, {
      timeZone: stockholmTimeZone,
      locale: sv,
    })

export const trrFormat = baseTrrFormat('yyyy-MM-dd')
export const trrFormatOccasionDate = baseTrrFormat('d MMM')
export const trrFormatTime = baseTrrFormat('H:mm')
export const trrFormatMeetingDateSummary = baseTrrFormat('EEEE d MMMM yyyy')
export const trrFormatMeetingTimeSummary = baseTrrFormat('HH:mm')

export const timeParser = (date: Date | string) => {
  if (typeof date === 'string') {
    return date.slice(date.indexOf('T') + 1, date.length - 4)
  }
  return date.getTime.toString()
}

export const getErrorMsg = (name: string, value: string) => {
  const length = value.length
  const empty = value.trim() === ''
  const validEmail = /\S+@\S+\.\S+/
  const validPhone =
    !/^\+?[- ()\d]+$/.test(value) || value.replace(/[^0-9]/g, '').length < 8
  const validOrgNumber = /^\d{6}[-]?\d{4}$/.test(value)
  switch (name) {
    case 'colleagueEmail':
    case 'email':
      if (empty) {
        return 'Ange din mejladress'
      } else if (!validEmail.test(value)) {
        return 'Ange en giltig mejladress'
      } else if (length > 241) {
        return 'Max 241 tecken'
      } else {
        return ''
      }
    case 'phoneNumber':
      if (empty) {
        return 'Ange ditt telefonnummer'
      } else if (validPhone) {
        return 'Ange ditt telefonnummer'
      } else if (length > 30) {
        return 'Max 30 tecken'
      } else {
        return ''
      }
    case 'organizationNumber':
      if (empty) {
        return 'Ange ett organisationsnummer'
      } else if (!validOrgNumber) {
        return 'Ange ett korrekt organisationsnummer (10 siffror med eller utan bindestreck)'
      } else {
        return ''
      }
  }
}
