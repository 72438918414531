import React from 'react'
import {
  Card,
  CardHeader,
  CardMetaDataGroup,
  Chip,
  Icons,
  MetaData,
  Typography,
  TypographyGroup,
} from '@trr/common-component-library'
import { format, isFuture, isPast, parseISO } from 'date-fns'
import { sv } from 'date-fns/locale'

import { useEpiString } from '../../../Utils/EpiStrings'
import { trrDate, trrFormatTime } from '../../../Utils/Helpers'
import { TemporaryCustomerActivityBlock } from '../../../App.types'

import s from './CardView.module.scss'

export interface CardViewProps {
  activities: TemporaryCustomerActivityBlock[]
}

export const CardView: React.FunctionComponent<CardViewProps> = ({
  activities,
}) => {
  const t = useEpiString()
  return (
    <div className={s.CardWrapper}>
      <TypographyGroup>
        <Typography variant="h1">{t('cardView.heading')}</Typography>
        <Typography variant="body1" className={s.CardIngress}>
          {t('cardView.ingress')}
        </Typography>
      </TypographyGroup>

      {activities.map((item) => {
        if (isFuture(parseISO(item.properties.startDate))) {
          const itemSignupDateHasPassed = isPast(
            parseISO(item.properties.lastSignupDate)
          )
          const itemDetails = item.properties
          const date = format(trrDate(itemDetails.startDate), 'd MMM yyyy', {
            locale: sv,
          })
          const startTime = trrFormatTime(itemDetails.startDate)

          return (
            <Card
              className={s.Card}
              key={item.id}
              data-id={item.id}
              linkTo={`/arbetsgivare/aktiviteter/hitta-aktiviteter/aktivitet?id=${item.id}`}
            >
              <CardHeader>{itemDetails.heading}</CardHeader>
              <CardMetaDataGroup>
                <MetaData
                  icon={
                    <Icons.ContactsIcon title={itemDetails.targetAudience} />
                  }
                  text={`För ${itemDetails.targetAudience}`}
                />
                <MetaData
                  icon={<Icons.CalendarOutlinedIcon title={'Datum och Tid'} />}
                  text={`${date} kl ${startTime}`}
                />
                {!itemDetails.isOnline && (
                  <MetaData
                    icon={<Icons.LocationIcon title={'Plats'} />}
                    text={`${itemDetails.location}`}
                  />
                )}
                {itemDetails.hasOccasions && (
                  <MetaData text="Flera delmoment" icon={<></>} />
                )}
                <div className={s.CardChips}>
                  {itemDetails.isOnline && (
                    <Chip className={s.OnlineChip} color="green">
                      Online
                    </Chip>
                  )}
                  {itemSignupDateHasPassed ? (
                    <Chip className={s.FullChip} color="red">
                      {t('cardView.activityHasPassed')}
                    </Chip>
                  ) : (
                    itemDetails.isFull && (
                      <Chip className={s.FullChip} color="red">
                        {t('cardView.activityIsFull')}
                      </Chip>
                    )
                  )}
                </div>
              </CardMetaDataGroup>
            </Card>
          )
        } else {
          return null
        }
      })}
    </div>
  )
}
