import React, { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Typography } from '@trr/common-component-library'
import { useEpiString } from '../../../../Utils/EpiStrings'

import s from '../ActivityView.module.scss'
import { getErrorMsg } from 'Utils/Helpers'
import { omit } from 'ramda'
import { ActivityViewModals } from './ActivityViewModals'
import {
  Colleague,
  FormDetailData,
  FormDetailErrors,
  IFormRequestData,
  InputErrors,
  ModalStatus,
} from '../ActivityView.types'
import { ActivityViewFormMainFields } from './ActivityViewFormMainFields'
import {
  errorInitialState,
  genericErrorMessage,
  initialInputErrors,
  initialState,
  initialStateColleague,
  initialStateModalStatus,
} from '../ActivityViewInitialStates'
import { TemporaryCustomerActivityBlock } from '../../../../App.types'
import getConfig from '../../../../Utils/getConfig'

export const ActivityViewForm: React.FunctionComponent<{
  activity: TemporaryCustomerActivityBlock
}> = ({ activity }) => {
  const t = useEpiString()

  const [formDetails, setFormDetails] = useState<FormDetailData>(initialState)
  const [formDetailsError, setFormDetailsError] =
    useState<FormDetailErrors>(errorInitialState)
  const [colleagueDetails, setColleagueDetails] = useState<Colleague>(
    initialStateColleague
  )
  const [inputErrors, setInputErrors] =
    useState<InputErrors>(initialInputErrors)
  const [modalStatus, setModalStatus] = useState<ModalStatus>(
    initialStateModalStatus
  )

  const [failSystemFeedBackStatus, setFailSystemFeedBackStatus] =
    useState<boolean>(false)

  const hasAllDetails = useCallback((obj: FormDetailData | Colleague) => {
    const cleansedObj = omit(['notes'], obj)
    return Object.values(cleansedObj).every((value) => value)
  }, [])

  const setErrorMessage = useCallback(
    (name: string, message: string | undefined) => {
      setInputErrors((state) => ({
        ...state,
        [name]: message,
      }))
    },
    []
  )
  const validateInput = useCallback(
    (
      name: 'phoneNumber' | 'email' | 'colleagueEmail' | 'organizationNumber',
      state: any
    ) => {
      const stateName = name === 'colleagueEmail' ? 'email' : name
      const message = getErrorMsg(name, state[stateName])

      setErrorMessage(name, message)
    },
    [setErrorMessage]
  )

  const handleInputOnBlurValidation = useCallback(
    (key: keyof FormDetailErrors) => (e: any) => {
      const { value } = e.target
      value === ''
        ? setFormDetailsError((prev) => ({ ...prev, [key]: true }))
        : setFormDetailsError((prev) => ({ ...prev, [key]: false }))
    },
    []
  )

  const handleInputChange = useCallback(
    (key: keyof FormDetailData) => (e: any) => {
      const { value } = e.target
      typeof value === 'string' &&
        setFormDetails((prev) => ({ ...prev, [key]: value }))
    },
    []
  )

  const handleInputChangeColleague = useCallback(
    (key: keyof Colleague) => (e: any) => {
      const inputValue = e?.target?.value
      typeof inputValue === 'string' &&
        setColleagueDetails((prev) => ({
          ...prev,
          [key]: inputValue,
          id: uuidv4(),
        }))
    },
    []
  )

  const handleAddColleagueField = useCallback(() => {
    setFormDetails((prev) => ({
      ...prev,
      colleagues: [...prev.colleagues, colleagueDetails],
    }))
    setModalStatus((prev) => ({ ...prev, colleagueModal: false }))
    setColleagueDetails(initialStateColleague)
  }, [colleagueDetails])

  const toggleChecked = useCallback(() => {
    setFormDetails((prev) => ({ ...prev, Approved: !prev.Approved }))
  }, [])

  const handleRemoveColleague = useCallback(
    (id: string) => {
      const stateWithRemovedColleague = formDetails.colleagues.filter(
        (colleague) => colleague.id !== id
      )
      setFormDetails((prev) => ({
        ...prev,
        colleagues: stateWithRemovedColleague,
      }))
    },
    [formDetails.colleagues]
  )

  const handleSubmit = useCallback(() => {
    const cleansedObj = omit(['Approved'], formDetails)
    const requestData: IFormRequestData = {
      activityName: activity.properties.heading,
      activityLocation: activity.properties.location || 'Online',
      activityStartDate: activity.properties.startDate,
      ...cleansedObj,
    }

    fetch(`${getConfig().API_URL}/CustomerActivity`, {
      method: 'POST',
      body: JSON.stringify(requestData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          setFailSystemFeedBackStatus(true)
        } else {
          setModalStatus({ ...modalStatus, successModal: true })
          setFormDetails(initialState)
          setColleagueDetails(initialStateColleague)
        }
      })
      .catch(() => {
        setFailSystemFeedBackStatus(true)
      })
  }, [formDetails, modalStatus, activity])

  const handleModalStatus = useCallback(
    (key: keyof ModalStatus) => {
      setModalStatus({ ...modalStatus, [key]: !modalStatus[key] })
    },
    [modalStatus]
  )

  return (
    <div role="form" className={s.ActivityForm}>
      <Typography variant="h2" component="h1" gutterBottom>
        {t('form.heading')}
      </Typography>
      <ActivityViewFormMainFields
        handleInputChange={handleInputChange}
        formDetails={formDetails}
        formDetailsErrors={formDetailsError}
        genericErrorMessage={genericErrorMessage}
        handleInputOnBlurValidation={handleInputOnBlurValidation}
        validateInput={validateInput}
        inputErrors={inputErrors}
        handleRemoveColleague={handleRemoveColleague}
        handleModalStatus={handleModalStatus}
        handleSubmit={handleSubmit}
        hasAllDetails={hasAllDetails}
        toggleChecked={toggleChecked}
        failSystemFeedBackStatus={failSystemFeedBackStatus}
        setFailSystemFeedBackStatus={setFailSystemFeedBackStatus}
      />
      <ActivityViewModals
        colleagueDetails={colleagueDetails}
        validateInput={validateInput}
        formDetailsErrors={formDetailsError}
        genericErrorMessage={genericErrorMessage}
        hasAllDetails={hasAllDetails}
        handleModalStatus={handleModalStatus}
        inputErrors={inputErrors}
        modalStatus={modalStatus}
        handleAddColleagueField={handleAddColleagueField}
        handleInputChangeColleague={handleInputChangeColleague}
        handleInputOnBlurValidation={handleInputOnBlurValidation}
      />
    </div>
  )
}
