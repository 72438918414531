import React from 'react'
import {
  Button,
  InputField,
  Modal,
  Typography,
} from '@trr/common-component-library'
import { useEpiString } from '../../../../Utils/EpiStrings'

import s from '../ActivityView.module.scss'

import PositivismIllustration from 'KundAktiviteterTemp/Components/Illustrations/PositivismIllustration'
import {
  Colleague,
  FormDetailData,
  FormDetailErrors,
  InputErrors,
  ModalStatus,
} from '../ActivityView.types'

interface ActivityViewModalsProps {
  formDetailsErrors: FormDetailErrors
  inputErrors: InputErrors
  modalStatus: ModalStatus
  genericErrorMessage: string
  hasAllDetails: (obj: FormDetailData | Colleague) => boolean
  handleAddColleagueField: () => void
  handleModalStatus: (key: keyof ModalStatus) => void
  handleInputChangeColleague: (key: keyof Colleague) => (e: any) => void
  handleInputOnBlurValidation: (key: keyof FormDetailErrors) => (e: any) => void
  colleagueDetails: Colleague
  validateInput: (
    name: 'phoneNumber' | 'email' | 'colleagueEmail',
    state: any
  ) => void
}

export const ActivityViewModals: React.FunctionComponent<ActivityViewModalsProps> =
  ({
    formDetailsErrors,
    modalStatus,
    genericErrorMessage,
    inputErrors,
    colleagueDetails,
    hasAllDetails,
    handleAddColleagueField,
    handleModalStatus,
    handleInputChangeColleague,
    handleInputOnBlurValidation,
    validateInput,
  }) => {
    const t = useEpiString()

    return (
      <>
        {modalStatus.colleagueModal && (
          <Modal
            customClass={s.AddColleagueModal}
            title={t('form.modalTitle')}
            primaryButton={
              <Button
                primary
                disabled={
                  inputErrors.colleagueEmail !== '' ||
                  !hasAllDetails(colleagueDetails)
                }
                onClick={handleAddColleagueField}
              >
                {t('form.modalButtonText')}
              </Button>
            }
            onClose={() => handleModalStatus('colleagueModal')}
            active={modalStatus.colleagueModal}
          >
            <InputField
              id={'colleagueFirstName'}
              label={t('form.colleague.firstName')}
              onChange={handleInputChangeColleague('firstName')}
              onBlur={handleInputOnBlurValidation('colleagueFirstName')}
              errorMsg={
                formDetailsErrors.colleagueFirstName ? genericErrorMessage : ''
              }
              value={colleagueDetails.firstName}
            />
            <InputField
              id={'colleagueSurName'}
              label={t('form.colleague.surName')}
              onChange={handleInputChangeColleague('lastName')}
              onBlur={handleInputOnBlurValidation('colleagueLastName')}
              errorMsg={
                formDetailsErrors.colleagueLastName ? genericErrorMessage : ''
              }
              value={colleagueDetails.lastName}
            />
            <InputField
              label={t('form.colleague.email')}
              id={'colleagueEmail'}
              onChange={handleInputChangeColleague('email')}
              onBlur={() => validateInput('colleagueEmail', colleagueDetails)}
              errorMsg={inputErrors.colleagueEmail}
              value={colleagueDetails.email}
            />
            <InputField
              id={'colleagueRole'}
              label={t('form.colleague.role')}
              onChange={handleInputChangeColleague('role')}
              onBlur={handleInputOnBlurValidation('colleagueRole')}
              errorMsg={
                formDetailsErrors.colleagueRole ? genericErrorMessage : ''
              }
              value={colleagueDetails.role}
            />
          </Modal>
        )}
        {modalStatus.successModal && (
          <Modal
            customClass={s.SuccessModal}
            title={t('form.successModalTitle')}
            primaryButton={
              <Button primary onClick={() => handleModalStatus('successModal')}>
                {t('form.successModalButtonText')}
              </Button>
            }
            onClose={() => handleModalStatus('successModal')}
            active={modalStatus.successModal}
          >
            <PositivismIllustration customClass={s.SuccessSvg} />
            <Typography variant="body1">
              {t('form.successModalText')}
            </Typography>
          </Modal>
        )}
      </>
    )
  }
