import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { EpiStringProvider } from './Utils/EpiStrings'
import { AppShellConfigurationProps } from './App.types'
import { CardView } from 'KundAktiviteterTemp/Components/CardView/CardView'
import { ActivityView } from 'KundAktiviteterTemp/Components/ActivityView/ActivityView'

import s from './App.module.scss'
const App: React.FunctionComponent<AppShellConfigurationProps> = ({
  content: {
    aktiviteter: { contentBlockArea: activities },
    textContent,
  },
}) => {
  enum ViewName {
    Cards = 'Start',
    Activity = 'Activity',
  }
  const { search } = useLocation()

  const [currentView, setCurrentView] = useState<ViewName>(ViewName.Cards)
  const [currentId, setCurrentId] = useState<number>()
  useEffect(() => {
    if (search.includes('?id')) {
      setCurrentView(ViewName.Activity)
      setCurrentId(parseInt(search.split('?id=')[1]))
    } else {
      setCurrentView(ViewName.Cards)
    }
  }, [ViewName.Activity, ViewName.Cards, search])
  const currentActivity = activities.find((item) => item.id === currentId)
  return (
    <EpiStringProvider value={textContent}>
      <div className={s.AppWrapper}>
        {currentView === ViewName.Cards && <CardView activities={activities} />}
        {currentView === ViewName.Activity && currentActivity && (
          <ActivityView activity={currentActivity} />
        )}
      </div>
    </EpiStringProvider>
  )
}

export default App
