import React from 'react'
import {
  Button,
  Checkbox,
  Icons,
  InputField,
  Link,
  SystemFeedback,
  TextArea,
  Typography,
} from '@trr/common-component-library'
import { useEpiString } from '../../../../Utils/EpiStrings'

import s from '../ActivityView.module.scss'
import {
  Colleague,
  FormDetailData,
  FormDetailErrors,
  InputErrors,
  ModalStatus,
} from '../ActivityView.types'

interface ActivityViewFormMainFieldsProps {
  handleInputChange: (key: keyof FormDetailData) => (e: any) => void
  formDetails: FormDetailData
  formDetailsErrors: FormDetailErrors
  genericErrorMessage: string
  handleInputOnBlurValidation: (key: keyof FormDetailErrors) => (e: any) => void
  validateInput: (
    name: 'phoneNumber' | 'email' | 'colleagueEmail' | 'organizationNumber',
    state: any
  ) => void
  inputErrors: InputErrors
  handleRemoveColleague: (id: string) => void
  hasAllDetails: (obj: FormDetailData | Colleague) => boolean
  handleModalStatus: (key: keyof ModalStatus) => void
  handleSubmit: () => void
  toggleChecked: () => void
  failSystemFeedBackStatus: boolean
  setFailSystemFeedBackStatus: React.Dispatch<React.SetStateAction<boolean>>
}

export const ActivityViewFormMainFields: React.FunctionComponent<ActivityViewFormMainFieldsProps> =
  ({
    handleInputChange,
    formDetails,
    formDetailsErrors,
    genericErrorMessage,
    handleInputOnBlurValidation,
    validateInput,
    inputErrors,
    handleRemoveColleague,
    handleModalStatus,
    handleSubmit,
    hasAllDetails,
    toggleChecked,
    failSystemFeedBackStatus,
    setFailSystemFeedBackStatus,
  }) => {
    const t = useEpiString()

    return (
      <>
        <InputField
          label={t('form.firstName')}
          onChange={handleInputChange('firstName')}
          value={formDetails.firstName}
          errorMsg={formDetailsErrors.firstName ? genericErrorMessage : ''}
          onBlur={handleInputOnBlurValidation('firstName')}
        />

        <InputField
          label={t('form.surName')}
          onChange={handleInputChange('lastName')}
          value={formDetails.lastName}
          errorMsg={formDetailsErrors.lastName ? genericErrorMessage : ''}
          onBlur={handleInputOnBlurValidation('lastName')}
        />
        <InputField
          label={t('form.cell')}
          onChange={handleInputChange('phoneNumber')}
          value={formDetails.phoneNumber}
          errorMsg={inputErrors.phoneNumber}
          onBlur={() => validateInput('phoneNumber', formDetails)}
        />

        <InputField
          label={t('form.email')}
          onChange={handleInputChange('email')}
          value={formDetails.email}
          errorMsg={inputErrors.email}
          onBlur={() => validateInput('email', formDetails)}
        />
        <InputField
          label={t('form.company')}
          onChange={handleInputChange('organizationName')}
          value={formDetails.organizationName}
          errorMsg={
            formDetailsErrors.organizationName ? genericErrorMessage : ''
          }
          onBlur={handleInputOnBlurValidation('organizationName')}
        />

        <InputField
          label={t('form.orgNr')}
          onChange={handleInputChange('organizationNumber')}
          value={formDetails.organizationNumber}
          errorMsg={inputErrors.organizationNumber}
          onBlur={() => validateInput('organizationNumber', formDetails)}
        />
        <InputField
          label={t('form.role')}
          onChange={handleInputChange('role')}
          value={formDetails.role}
          errorMsg={formDetailsErrors.role ? genericErrorMessage : ''}
          onBlur={handleInputOnBlurValidation('role')}
        />

        <InputField
          label={t('form.location')}
          onChange={handleInputChange('location')}
          value={formDetails.location}
          errorMsg={formDetailsErrors.location ? genericErrorMessage : ''}
          onBlur={handleInputOnBlurValidation('location')}
        />
        <TextArea
          label={t('form.additionalInfo')}
          onChange={handleInputChange('notes')}
          content={formDetails.notes}
        />
        <div className={s.AddColleagueSegment}>
          <Typography variant="h4" component="h2" gutterBottom>
            {t('form.addColleaguesHeading')}
          </Typography>
          {formDetails.colleagues.length > 0 ? (
            <div className={s.AddedColleagues}>
              {formDetails.colleagues.map((colleague) => {
                return (
                  <Typography variant="body1" key={colleague.email}>
                    <span>
                      {`${colleague.firstName} ${colleague.lastName}`}{' '}
                      <span className={s.AddedColleaguesDivider}>|</span>{' '}
                      {colleague.email}
                    </span>
                    <Button
                      customClass={s.AddedColleaguesRemoveButton}
                      icon
                      onClick={() => handleRemoveColleague(colleague.id)}
                      testSelector="iconselector"
                    >
                      <Icons.TrashcanIcon color={s.textColor} size={16} />
                    </Button>
                  </Typography>
                )
              })}
            </div>
          ) : (
            <Typography variant="body1">
              {t('form.addColleaguesIngress')}
            </Typography>
          )}
          <Button
            secondary
            customClass={s.AddColleagueShowModalButton}
            onClick={() => handleModalStatus('colleagueModal')}
          >
            {t('form.addColleagueButton')}
          </Button>
        </div>

        <Checkbox
          label={t('form.checkboxText')}
          checked={formDetails.Approved}
          onChange={toggleChecked}
        />
        <Link
          external
          customClass={s.FormReadMoreLink}
          noChevron
          link={t('form.linkUrl')}
          label={t('form.linkText')}
        />
        {failSystemFeedBackStatus && (
          <div className={s.ErrorMessage}>
            <SystemFeedback
              type="error"
              content={t('form.failedSubmit')}
              onClose={() => setFailSystemFeedBackStatus(false)}
            />
          </div>
        )}
        <Button
          customClass={s.FormSubmitButton}
          onClick={handleSubmit}
          primary
          testSelector="primaryselector"
          disabled={
            inputErrors.phoneNumber !== '' ||
            inputErrors.email !== '' ||
            !hasAllDetails(formDetails)
          }
        >
          {t('form.submitButtonText')}
        </Button>
      </>
    )
  }
