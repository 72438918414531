import {
  Colleague,
  FormDetailData,
  FormDetailErrors,
  ModalStatus,
} from './ActivityView.types'

export const errorInitialState: FormDetailErrors = {
  firstName: false,
  lastName: false,
  organizationName: false,
  organizationNumber: false,
  role: false,
  location: false,
  colleagueFirstName: false,
  colleagueLastName: false,
  colleagueRole: false,
}

export const initialState: FormDetailData = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  organizationName: '',
  organizationNumber: '',
  role: '',
  location: '',
  notes: '',
  colleagues: [],
  Approved: false,
}

export const initialStateColleague: Colleague = {
  id: '',
  firstName: '',
  lastName: '',
  role: '',
  email: '',
}

export const initialInputErrors = {
  email: '',
  phoneNumber: '',
  colleagueEmail: '',
  colleagueRole: '',
  organizationNumber: '',
}

export const initialStateModalStatus: ModalStatus = {
  colleagueModal: false,
  successModal: false,
}

export const genericErrorMessage = 'Fältet får inte lämnas tomt'
