import React from 'react'
import { parseISO, isPast } from 'date-fns'

import { TemporaryCustomerActivityBlock } from '../../../App.types'
import { ActivityViewMarkup } from './SubComponents/ActivityViewMarkup'

import s from './ActivityView.module.scss'
import { ActivityViewForm } from './SubComponents/ActivityViewForm'

export interface ActivityViewProps {
  activity: TemporaryCustomerActivityBlock
}

export const ActivityView: React.FunctionComponent<ActivityViewProps> = ({
  activity,
}) => {
  const itemSignupDateHasPassed = activity.properties.lastSignupDate
    ? isPast(parseISO(activity.properties.lastSignupDate))
    : false
  return (
    <>
      <div className={s.ActivitySectionWrapper}>
        <ActivityViewMarkup activity={activity} />
      </div>
      {!activity.properties.isFull && !itemSignupDateHasPassed && (
        <div className={s.ActivitySectionWrapper}>
          <ActivityViewForm activity={activity} />
        </div>
      )}
    </>
  )
}
