import React from 'react'
import {
  Chip,
  Icons,
  MetaData,
  Typography,
  TypographyGroup,
} from '@trr/common-component-library'
import { format, isPast, parseISO } from 'date-fns'
import { sv } from 'date-fns/locale'
import { useEpiString } from '../../../../Utils/EpiStrings'
import { trrDate } from '../../../../Utils/Helpers'
import { TemporaryCustomerActivityBlock } from '../../../../App.types'

import s from '../ActivityView.module.scss'

export interface ActivityViewMarkupProps {
  activity: TemporaryCustomerActivityBlock
}

export const ActivityViewMarkup: React.FunctionComponent<ActivityViewMarkupProps> =
  ({ activity }) => {
    const t = useEpiString()

    const activityDetails = activity.properties
    const activityDatesAndTimesRowBreak =
      activityDetails.datesAndTimes.split(/\n/g)

    const date = format(trrDate(activityDetails.lastSignupDate), 'yyyy-MM-dd', {
      locale: sv,
    })

    const itemSignupDateHasPassed = isPast(
      parseISO(activityDetails.lastSignupDate)
    )

    return (
      <>
        <Typography variant="h2" component="h1" gutterBottom>
          {activityDetails.heading}
        </Typography>
        <div className={s.ActivityLocationAudience}>
          <MetaData
            className={s.ActivityAudience}
            icon={<Icons.ContactsIcon title={activityDetails.targetAudience} />}
            text={`För ${activityDetails.targetAudience}`}
          />
          {activityDetails.isOnline ? (
            <MetaData
              icon={<Icons.ScreenIcon title={'Online'} />}
              text={`Online`}
            />
          ) : (
            <MetaData
              icon={<Icons.LocationIcon title={activityDetails.location} />}
              text={`${activityDetails.location}`}
            />
          )}
        </div>
        <Typography variant="h4" component="h2" gutterBottom>
          {t('cardView.timeAndDate')}
        </Typography>

        <TypographyGroup
          className={s.ActivityDateAndTimesWrapper}
          gutterBottom={false}
        >
          {activityDatesAndTimesRowBreak.map((dateTime, i) => (
            <Typography
              variant="body1"
              key={i}
              className={s.ActivityDateAndTimes}
            >
              {dateTime}
            </Typography>
          ))}
        </TypographyGroup>

        {itemSignupDateHasPassed ? (
          <Chip className={s.FullChip} color="red">
            {t('cardView.activityHasPassed')}
          </Chip>
        ) : activityDetails.isFull ? (
          <Chip className={s.FullChip} color="red">
            {t('cardView.activityIsFull')}
          </Chip>
        ) : (
          <div className={s.ActivitySignupBlock}>
            <Typography component="h2" variant="h4" gutterBottom>
              {t('activityView.signupsAvailable')}
            </Typography>
            <time>
              {t('activityView.lastDayToSignup')} {date}
            </time>
          </div>
        )}

        <Typography variant="preamble" className={s.ActivityIngress}>
          {activityDetails.ingress}
        </Typography>
        <div
          className={s.ActivityMainBody}
          dangerouslySetInnerHTML={{ __html: activityDetails.mainBody }}
        />
      </>
    )
  }
