// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/@trr/toolkit/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/@trr/toolkit/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardView_CardChips__7iMwG{margin-top:24px}.CardView_CardChips__7iMwG .CardView_OnlineChip__2xh2R{margin-right:8px}.CardView_Card__15uS0{margin-bottom:16px}.CardView_CardIngress__1xZfK{font-weight:300;letter-spacing:-0.3px;line-height:1.5;font-size:1.25rem;margin-bottom:32px}@media(min-width: 768px){.CardView_CardIngress__1xZfK{font-size:1.375rem}}", "",{"version":3,"sources":["webpack://./CardView.module.scss"],"names":[],"mappings":"AACA,2BACE,eAAA,CACA,uDACE,gBAAA,CAGJ,sBACE,kBAAA,CAGF,6BACE,eAAA,CACA,qBAAA,CACA,eAAA,CACA,iBAAA,CACA,kBAAA,CAEF,yBACE,6BACE,kBAAA,CAAA","sourcesContent":["@import '@trr/common-component-library/lib/Styles/index.scss';\n.CardChips {\n  margin-top: gutter(3);\n  .OnlineChip {\n    margin-right: gutter(1);\n  }\n}\n.Card {\n  margin-bottom: gutter(2);\n}\n\n.CardIngress {\n  font-weight: 300;\n  letter-spacing: -0.3px;\n  line-height: 1.5;\n  font-size: 1.25rem;\n  margin-bottom: 32px;\n}\n@media (min-width: 768px) {\n  .CardIngress {\n    font-size: 1.375rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"purpleDark": "#392b64",
	"purple100": "#4c3e7a",
	"purple80": "#716595",
	"purple60": "#948baf",
	"purple40": "#b8b2ca",
	"purple20": "#dbd8e4",
	"purple10": "#edebf1",
	"green100": "#15958c",
	"green80": "#44aba3",
	"green60": "#73c0ba",
	"green40": "#a2d5d1",
	"green20": "#d0eae8",
	"green10": "#e7f4f3",
	"yellow100": "#f3d500",
	"yellow80": "#f5de33",
	"yellow60": "#f8e666",
	"yellow40": "#faee99",
	"yellow20": "#fdf7cc",
	"yellow10": "#fdfae5",
	"blue100": "#2546c0",
	"blue80": "#516bcd",
	"blue60": "#7c90d9",
	"blue40": "#a8b5e6",
	"blue20": "#d3daf2",
	"blue10": "#e9ecf8",
	"orange100": "#fa8554",
	"orange80": "#fb9d76",
	"orange60": "#fcb698",
	"orange40": "#fdcebb",
	"orange20": "#fee7dd",
	"orange10": "#fef2ed",
	"rose100": "#ea1845",
	"rose80": "#ee466a",
	"rose60": "#f2748f",
	"rose40": "#f7a3b5",
	"rose20": "#fbd1da",
	"rose10": "#fce7ec",
	"petrol100": "#0b605c",
	"petrol80": "#3c807d",
	"petrol60": "#6da09d",
	"petrol40": "#9dbfbe",
	"petrol20": "#cedfde",
	"petrol10": "#e6efee",
	"gray0": "#707070",
	"gray1": "#7c7d82",
	"gray2": "#a2a4aa",
	"gray3": "#cbcdd2",
	"gray4": "#dde0e7",
	"gray5": "#eff0f9",
	"gray6": "#f5f6fb",
	"white": "#fff",
	"black": "#000",
	"gray": "#707070",
	"textColor": "#404040",
	"disabledColor": "#dde0e7",
	"letterSpacingHeading": "-0.3px",
	"CardChips": "CardView_CardChips__7iMwG",
	"OnlineChip": "CardView_OnlineChip__2xh2R",
	"Card": "CardView_Card__15uS0",
	"CardIngress": "CardView_CardIngress__1xZfK"
};
export default ___CSS_LOADER_EXPORT___;
